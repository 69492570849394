const languageSelector = () => {
  const dataLang = Array.from(document.querySelectorAll('[data-lang]'));

  dataLang.forEach((item) => {
    const pageLang = item.dataset.lang;
    const url = document.querySelector(`[hreflang="${pageLang}"]`);
    
    if (!url) {
      return;
    }

    item.href = url.href;
  });
};

export default languageSelector;
