export default () => {
  const CLASS_ACTIVE = 'is-active';
  const toggles = Array.from(document.querySelectorAll('[data-js-toggle]'));

  if (!toggles.length) {
    return;
  }

  toggles.forEach((toggle) => {
    const toggleSwitch = toggle.querySelector('[data-js-toggle-switch]');

    toggleSwitch.addEventListener(
      'click',
      (e) => {
        e.stopPropagation();
        const status = toggle.classList.contains(CLASS_ACTIVE);

        toggles.forEach((el) => {
          el.classList.remove(CLASS_ACTIVE);
        });

        toggle.classList.toggle(CLASS_ACTIVE, !status);
      },
      false
    );

    document.addEventListener('click', () => {
      toggle.classList.remove(CLASS_ACTIVE);
    });
  });
};
