import cookieStatement from 'components/cookie-statement/js';
import modal from 'components/modal/js';
import hasJs from 'globals/js/has-js';
import header from 'components/header/js';
import toggle from 'globals/js/toggle';
import transitionsSupported from 'globals/js/transitions-supported';
import accordion from 'components/accordion/js';
import languageSelector from './components/language-selector';
import calculator from 'radius/dist/m2dm/js/calculator';

import nlCalculatorConfig from './components/calculator/nl';
import deCalculatorConfig from './components/calculator/de';

cookieStatement();
toggle();
hasJs();
header();
modal({
  animationsOn: transitionsSupported,
});
accordion();
languageSelector();
calculator(window.location.href.split('/')[3] === 'nl' ? nlCalculatorConfig : deCalculatorConfig);
