import Header, { IS_ACTIVE, OVERLAY_SELECTOR } from './header';
import { animate } from './utils';

export default class HeaderWithAnimations extends Header {
  constructor(headerEl, animationSpeed = 0.4) {
    super(headerEl);

    // Code compiled with babel6 in IE10 does not call the parent constructor
    // https://github.com/babel/babel/issues/3041
    this.headerEl = headerEl;
    this.isOpen = false;
    this.overlayEl = this.headerEl.querySelector(OVERLAY_SELECTOR);
    this.body = document.body;

    this.inProgress = false;
    this.config = {
      fromStyles: {
        opacity: 0,
      },
      toStyles: {
        opacity: 1,
      },
      animationSpeed,
    };
  }

  start() {
    super.start();

    if (this.hasOverlay) {
      this.overlayEl.style.transition = `linear ${this.config.animationSpeed}s all`;
    }
  }

  showMenu() {
    if (this.inProgress || this.isOpen) {
      return;
    }

    this.isOpen = true;
    this.inProgress = true;

    this.freezeBody();
    this.overlayEl.style.display = 'block';

    Object.assign(this.overlayEl.style, this.config.fromStyles);

    animate(this.overlayEl, this.config.toStyles).then(() => {
      this.headerEl.classList.add(IS_ACTIVE);
      this.inProgress = false;
    });
  }

  hideMenu() {
    if (this.inProgress || !this.isOpen) {
      return;
    }
    this.isOpen = false;
    this.inProgress = true;

    this.headerEl.classList.remove(IS_ACTIVE);
    animate(this.overlayEl, this.config.fromStyles).then(() => {
      this.unFreezeBody();
      this.overlayEl.style.display = 'none';
      this.inProgress = false;
    });
  }
}
