const cookies = {
  setCookie({
    cookieName = 'FCCookie',
    cookieValue = true,
    cookieSecure = true,
    cookieDays = 365,
    cookiePath = '/',
  }) {
    const expires = cookies.getDaysFromNow(cookieDays);
    const secured =
      cookieSecure && window.location.protocol === 'https:' ? 'secure' : '';
    document.cookie = `${cookieName}=${cookieValue};\
      expires=${expires};\
      path=${cookiePath};${secured}`;
  },

  getCookie(cookieName) {
    const name = `${cookieName}=`;
    const cookieArray = document.cookie.split(';');
    for (let i = 0; i < cookieArray.length; i += 1) {
      const cookie = cookieArray[i].trim();
      if (cookie.indexOf(name) === 0) {
        return cookie.substring(name.length, cookie.length);
      }
    }
    return '';
  },

  getDaysFromNow(days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    return date.toUTCString();
  },
};

export default cookies;
