"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _accounting = require("accounting");

function formatCurrency(value, currencyType) {
  var decimalPlaces = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 2;
  var thousandSeparator;
  var centSeparator;

  switch (currencyType) {
    case '£':
    case '$':
      thousandSeparator = ',';
      centSeparator = '.';
      break;

    case '€':
      thousandSeparator = '.';
      centSeparator = ',';
      break;

    default:
      thousandSeparator = ',';
      centSeparator = '.';
  }

  var formattedMoney = (0, _accounting.formatMoney)(value, currencyType, decimalPlaces, thousandSeparator, centSeparator);
  return formattedMoney;
}

var _default = formatCurrency;
exports["default"] = _default;