const deCalculatorConfig = {
    calculatorBlurb: 'Unverbindliche Beispielrechnung für Unternehmen mit sehr guter Kreditwürdigkeit. Sie erhalten kostenfrei ein persönliches Angebot unter "Finanzierung anfragen".',
    cta: {
        primaryCtaLink: '/de/firmenprofil-erstellen',
        primaryCtaLabel: 'Finanzierung anfragen',
        primaryCustomClass: '',
        connectorLabel: 'oder',
        secondaryCtaLink: '/de/firmenkredit-rate-ermitteln',
        secondaryCtaLabel: 'Mehr erfahren',
        secondaryCustomClass: '',
    },
    currencyType: '€',
    loanAmount: 140000,
    loanAmountLabel: 'Welche Summe möchten Sie finanzieren?',
    loanBreakDownLabels: {
        loanAmountLabel: 'Kreditbetrag',
        originationFeeLabel: 'Einmalige Vermittlungsgebühr',
        interestLabel: 'Zinsen',
        totalCostLabel: 'Gesamtkosten der Finanzierung',
        showLoanBreakdownLabel: 'Kreditdetails anzeigen',
        hideLoanBreakdownLabel: 'Kreditdetails ausblenden',
    },
    minLoanAmount: 10000,
    minAmountLabelWarning: 'Wir bieten leider nur Finanzierungen ab 10.000€. Bitte wählen Sie einen höheren Betrag aus.',
    maxLoanAmount: 250000,
    monthlyRepaymentLabel: 'Monat',
    ratesPerTerm: {
        6: { interestRate: 0.0599, completionRate: 0.02 },
        12: { interestRate: 0.0649, completionRate: 0.02 },
        24: { interestRate: 0.0699, completionRate: 0.03 },
        36: { interestRate: 0.0779, completionRate: 0.04 },
        48: { interestRate: 0.0829, completionRate: 0.04 },
        60: { interestRate: 0.0879, completionRate: 0.04 },
    },
    selectedLoanTerm: 12,
    termLengthLabel: 'Wie lang soll Ihre Finanzierung laufen?',
    termLengthYear: 'Monate',
};

export default deCalculatorConfig;
