"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var MONTHS_IN_YEAR = 12;

function calculateAmortisedValue(loanAmount, loanTerm, rates) {
  var totalLoanAmount = parseInt(loanAmount, 10);
  var _rates$loanTerm = rates[loanTerm],
      completionRate = _rates$loanTerm.completionRate,
      interestRate = _rates$loanTerm.interestRate;
  var completionFee = totalLoanAmount * completionRate;
  var loanWithFees = totalLoanAmount + completionFee;
  var monthlyInterestRate = interestRate / MONTHS_IN_YEAR;
  var denominator = Math.pow(1 + monthlyInterestRate, loanTerm) - 1;
  var monthlyPayment = loanWithFees * (monthlyInterestRate / denominator + monthlyInterestRate);
  var totalInterest = monthlyPayment * loanTerm - loanWithFees;
  var totalCost = loanWithFees + totalInterest;
  return {
    monthlyPayments: Math.round(monthlyPayment),
    totalCost: Math.round(totalCost),
    completionFee: Math.round(completionFee),
    interestFee: Math.round(totalInterest)
  };
}

var _default = calculateAmortisedValue;
exports["default"] = _default;