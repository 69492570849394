function animate(el, toStyles) {
  return new Promise((resolve) => {
    const transitionEnd = (event) => {
      //  make sure transition event happens on this element, not on any descendant
      if (event.target === el) {
        resolve();
        el.removeEventListener('transitionend', transitionEnd);
      }
    };
    el.addEventListener('transitionend', transitionEnd);

    forceReflow(el);

    Object.assign(el.style, toStyles);
  });
}

function forceReflow(el) {
  const div = document.createElement('div');
  div.innerHTML = el.clientLeft; //  force reflow: necessary to make animations work
}

function isEscapeKey(event) {
  return (
    event.which === 27 ||
    event.code === 'Escape' ||
    event.key === 'Escape' ||
    event.keyCode === 27
  );
}

export { animate, forceReflow, isEscapeKey };
