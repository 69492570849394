"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _default = function _default() {
  var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var defaults = arguments.length > 1 ? arguments[1] : undefined;
  var config = Object.assign({}, defaults, options); // Strip all non-numbers from loan amount

  function stripAllNonNumberCharacters(loanAmount) {
    var strippedString = typeof loanAmount === 'string' ? loanAmount.replace(/[^\d]/g, '') : loanAmount;
    return strippedString;
  } // If value is a string coerce to a number


  function coerceStringToNumber(loanAmount) {
    var numericString = typeof loanAmount === 'string' ? Number(loanAmount) : loanAmount;
    return numericString;
  }

  config.loanAmount = coerceStringToNumber(stripAllNonNumberCharacters(config.loanAmount));
  config.minLoanAmount = coerceStringToNumber(stripAllNonNumberCharacters(config.minLoanAmount));
  config.maxLoanAmount = coerceStringToNumber(stripAllNonNumberCharacters(config.maxLoanAmount));
  config.selectedLoanTerm = coerceStringToNumber(stripAllNonNumberCharacters(config.selectedLoanTerm));
  config.loanTerms = Object.keys(config.ratesPerTerm).map(function (term) {
    return Number(term);
  });
  return config;
};

exports["default"] = _default;