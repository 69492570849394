import A11yDialog from 'a11y-dialog';

const ANIMATION_DURATION = 0.4;
const BODY_CLASS_NAME = 'modal-open';

const selectors = {
  MODAL: '[data-js-modal]',
  PLAYER: '[data-js-player]',
  OPEN_BTN: '[data-js-modal-open]',
  CLOSE_BTN: '[data-js-modal-close]',
};

export default ({ animationsOn = true } = {}) => {
  const modalEls = Array.from(document.querySelectorAll(selectors.MODAL));
  const fromStyles = {
    opacity: 0,
  };
  const toStyles = {
    opacity: 1,
  };

  let modals = {};
  let players = {};
  let isYouTubeAPIReady = false;

  function onShow(modal) {
    Object.assign(modal.style, toStyles);
    document.body.classList.add(BODY_CLASS_NAME);

    modal.dispatchEvent(new Event('modalShow'));
  }

  function onHide(modal) {
    document.body.classList.remove(BODY_CLASS_NAME);

    modal.dispatchEvent(new Event('modalHide'));
  }

  function createPlayer(playerId) {
    return new YT.Player(playerId);
  }

  function checkModalForVideo(modalId) {
    const modalEl = document.getElementById(modalId);

    if (modalEl.dataset.jsContent === 'video' && isYouTubeAPIReady) {
      const elPlayer = modalEl.querySelector(selectors.PLAYER);
      const playerId = elPlayer.id;

      // Initiate the player object with the corresponding ID
      // after the API code downloads.
      if (!(playerId in players)) {
        players = Object.assign({}, players, {
          [playerId]: createPlayer(playerId),
        });
      }
    }
  }

  function handleOpenButtonClick(openButton) {
    const currentModalId = openButton.dataset.jsModalOpen;

    if (currentModalId in modals) {
      modals[currentModalId].show();
      checkModalForVideo(currentModalId);
    }
  }

  function addOpenButtonClickEventHandler() {
    document.addEventListener('click', (event) => {
      const openButtonMatch = event.target.closest(selectors.OPEN_BTN);

      if (openButtonMatch !== null) {
        event.preventDefault();
        handleOpenButtonClick(openButtonMatch);
      }
    });
  }

  function init() {
    window.onYouTubeIframeAPIReady = () => {
      isYouTubeAPIReady = true;
    };

    modalEls.forEach((el) => {
      //  doing animations entirely within Javascript
      if (animationsOn) {
        el.style.transition = `${ANIMATION_DURATION}s all ease-in-out`;
      }

      const modal = new A11yDialog(el);

      modals = Object.assign({}, modals, {
        [el.id]: modal,
      });

      const closeButtons = Array.from(el.querySelectorAll(selectors.CLOSE_BTN));
      //  also have to handle overlay clicks

      // move the modal to sit just before the closing body tag,
      // so that aria-hidden tags get added to all siblings
      // unless it needs to maintain current position in the document
      if (el.dataset.jsModal !== 'no-move') {
        document.body.appendChild(el);
      }

      el.style.opacity = 0;

      closeButtons.forEach((closeButton) => {
        closeButton.addEventListener('click', () => {
          Object.assign(el.style, fromStyles);

          if (animationsOn) {
            el.addEventListener('transitionend', function transitionEnd(event) {
              if (event.target === el) {
                // ignore transition events fired by children
                el.removeEventListener('transitionend', transitionEnd);
                modal.hide();
              }
            });
          } else {
            modal.hide();
          }

          if (el.dataset.jsContent === 'video') {
            const playerId = el.querySelector(selectors.PLAYER).id;

            if (playerId in players) {
              players[playerId].stopVideo();
            }
          }
        });
      });

      modal.on('show', onShow);
      modal.on('hide', onHide);
    });

    addOpenButtonClickEventHandler();
  }

  init();
};
