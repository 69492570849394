/**
 * section: M2DM/components/cookie-statement/javascript
 * title: Javascript
 *
 * purpose: Use to add show/hide functionality with the possibility to read and store the cookie statement.
 *
 * description: |
 *   The triggers are
 *     [data-js-cookie-statement] to instantiate the component
 *     [data-js-cookie-statement-cta] for the link
 *     [data-js-cookie-statement-path] for the configuration of the path
 *
 * markup: components/cookie-statement/cookie-statement
 *
 */

import cookies from '../../../globals/js/cookies';

export default () => {
  let selectorButtonDismiss;
  let cookieStatementPath;

  // Component configurable values
  const COOKIE_STATEMENT_NAME = 'FCCookieStatement';
  const COOKIE_STATEMENT_DAYS_FROM_NOW = 3653;

  // Values from the HTML markup
  const CLASS_ENABLED = 'is-enabled';
  const selectorCookieStatement = document.querySelector(
    '[data-js-cookie-statement]'
  );
  if (selectorCookieStatement) {
    selectorButtonDismiss = selectorCookieStatement.querySelector(
      '[data-js-cookie-statement-cta]'
    );
    cookieStatementPath = selectorCookieStatement.getAttribute(
      'data-js-cookie-statement-path'
    );

    if (!cookies.getCookie(COOKIE_STATEMENT_NAME)) {
      selectorCookieStatement.className += ` ${CLASS_ENABLED}`;
      selectorButtonDismiss.addEventListener('click', dismissCookieStatement);
    }
  }

  function dismissCookieStatement(event) {
    const cookieObj = {
      cookieName: COOKIE_STATEMENT_NAME,
      cookieValue: true,
      cookieDays: COOKIE_STATEMENT_DAYS_FROM_NOW,
      cookiePath: cookieStatementPath,
    };
    event.preventDefault();
    selectorCookieStatement.className = selectorCookieStatement.className.replace(
      CLASS_ENABLED,
      ''
    );
    cookies.setCookie(cookieObj);
  }
};
