import { isEscapeKey } from './utils';

export const IS_ACTIVE = 'is-active';

export const MORE_SELECTOR = '[data-js-overlay-open]';
export const HIDE_SELECTOR = '[data-js-overlay-close]';
export const OVERLAY_SELECTOR = '[data-js-header-overlay]';
export const BODY_CLASS_NAME = 'header-overlay-open';

export default class Header {
  constructor(headerEl) {
    this.headerEl = headerEl;
    this.isOpen = false;
    this.overlayEl = this.headerEl.querySelector(OVERLAY_SELECTOR);
    this.hasOverlay = !!this.overlayEl;
    this.body = document.body;
  }

  start() {
    if (this.hasOverlay) {
      this.bindHandlers();
    }
  }

  showMenu() {
    if (this.isOpen) {
      return;
    }
    this.isOpen = true;

    this.freezeBody();
    this.overlayEl.style.display = 'block';
    this.headerEl.classList.add(IS_ACTIVE);
  }

  hideMenu() {
    if (!this.isOpen) {
      return;
    }
    this.isOpen = false;
    this.hideOverlay();
    this.headerEl.classList.remove(IS_ACTIVE);
    this.unFreezeBody();
  }

  bindHandlers() {
    const moreButton = this.headerEl.querySelector(MORE_SELECTOR);
    const hideButton = this.headerEl.querySelector(HIDE_SELECTOR);

    if (moreButton) {
      moreButton.addEventListener('click', this.showMenu.bind(this));
    }
    hideButton.addEventListener('click', this.hideMenu.bind(this));
    document.addEventListener('keydown', this.escapeKeyListener.bind(this));
  }

  freezeBody() {
    this.body.classList.add(BODY_CLASS_NAME);
  }

  unFreezeBody() {
    this.body.classList.remove(BODY_CLASS_NAME);
  }

  hideOverlay() {
    this.overlayEl.style.display = 'none';
  }

  escapeKeyListener(event) {
    if (isEscapeKey(event)) {
      this.hideMenu();
    }
  }
}
