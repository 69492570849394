const nlLimConfig = {
    calculatorBlurb: 'Deze berekening is indicatief en dient alleen als richtlijn gebruikt te worden. Het bedrag is gebaseerd op een lening in de risicoklasse A+.',
    cta: {
        primaryCtaLink: '/nl/kredietaanvraag-registreren',
        primaryCtaLabel: 'Start aanvraag',
        primaryCustomClass: '',
        connectorLabel: 'of',
        secondaryCtaLink: '/nl/online-krediet-aanvragen',
        secondaryCtaLabel: 'bekijk opbouw kosten',
        secondaryCustomClass: '',
    },
    currencyType: '€',
    loanAmount: 120000,
    loanAmountLabel: 'Hoeveel wil je lenen?',
    loanBreakDownLabels: {
        loanAmountLabel: 'Leenbedrag',
        originationFeeLabel: 'Aanvraag kosten',
        interestLabel: 'Rente',
        totalCostLabel: 'Totale kosten van de lening',
        showLoanBreakdownLabel: 'Open uitsplitsing van de kosten',
        hideLoanBreakdownLabel: 'Verberg uitsplitsing van de kosten',
    },
    minLoanAmount: 10000,
    minAmountLabelWarning: 'De waarde moet tussen de € 10.000 en € 250.000 liggen',
    maxLoanAmount: 250000,
    monthlyRepaymentLabel: 'Maand',
    ratesPerTerm: {
        6: { interestRate: 0.0249, completionRate: 0.02 },
        12: { interestRate: 0.0399, completionRate: 0.02 },
        24: { interestRate: 0.0449, completionRate: 0.03 },
        36: { interestRate: 0.0499, completionRate: 0.04 },
        48: { interestRate: 0.0549, completionRate: 0.04 },
        60: { interestRate: 0.0599, completionRate: 0.04 },
    },
    selectedLoanTerm: 12,
    termLengthLabel: 'Wat is de gewenste looptijd van de lening?',
    termLengthYear: 'Maanden',
};

const nlNonLimConfig = {
    calculatorBlurb: 'Deze berekening is indicatief en dient alleen als richtlijn gebruikt te worden. Het bedrag is gebaseerd op een lening in de risicoklasse A.',
    cta: {
        primaryCtaLink: '/nl/kredietaanvraag-registreren',
        primaryCtaLabel: 'Bekijk direct of je in aanmerking komt',
        primaryCustomClass: '',
        connectorLabel: '',
        secondaryCtaLink: '',
        secondaryCtaLabel: '',
        secondaryCustomClass: '',
    },
    currencyType: '€',
    loanAmount: 45000,
    loanAmountLabel: 'Hoeveel wil je lenen?',
    loanBreakDownLabels: {
        loanAmountLabel: 'Leenbedrag',
        originationFeeLabel: 'Aanvraag kosten',
        interestLabel: 'Rente',
        totalCostLabel: 'Totale kosten van de lening',
        showLoanBreakdownLabel: 'Open uitsplitsing van de kosten',
        hideLoanBreakdownLabel: 'Verberg uitsplitsing van de kosten',
    },
    minLoanAmount: 10000,
    minAmountLabelWarning: 'De waarde moet tussen de € 10.000 en € 100.000 liggen',
    maxLoanAmount: 100000,
    monthlyRepaymentLabel: 'Maand',
    ratesPerTerm: {
        6: { interestRate: 0.0399, completionRate: 0.02 },
        12: { interestRate: 0.0599, completionRate: 0.02 },
        24: { interestRate: 0.0649, completionRate: 0.03 },
        36: { interestRate: 0.0699, completionRate: 0.04 },
        48: { interestRate: 0.0749, completionRate: 0.04 },
        60: { interestRate: 0.0799, completionRate: 0.04 },
    },
    selectedLoanTerm: 12,
    termLengthLabel: 'Wat is de gewenste looptijd van de lening?',
    termLengthYear: 'Maanden',
};

// `kredietcalculator` in the URL is the only information available at that time to differenciate the calculator pages 
const nlCalculatorConfig = window.location.href.split('/')[5] === 'kredietcalculator' ? nlLimConfig : nlNonLimConfig;

export default nlCalculatorConfig;
