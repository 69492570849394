import Header from './header';
import HeaderWithAnimations from './header-with-animations';
import transitionsSupported from '../../../globals/js/transitions-supported';

export default () => {
  const headerEl = document.querySelector('[data-js-header]');

  if (headerEl) {
    let header;
    if (transitionsSupported) {
      header = new HeaderWithAnimations(headerEl);
    } else {
      header = new Header(headerEl);
    }
    header.start();
  }
};
