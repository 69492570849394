export default () => {
  const CLASS_ACTIVE = 'is-active';

  const accordions = Array.from(
    document.querySelectorAll('[data-js-accordion]')
  );

  if (!accordions.length) {
    return;
  }

  accordions.forEach((accordion) => {
    const items = Array.from(
      accordion.querySelectorAll('[data-js-accordion-item]')
    );
    const triggers = Array.from(
      accordion.querySelectorAll('[data-js-accordion-trigger]')
    );

    items.forEach((item) => {
      item.classList.remove(CLASS_ACTIVE);

      item.addEventListener(
        'click',
        (e) => {
          const el = e.target;
          if (triggers.includes(el)) {
            const status = item.classList.contains(CLASS_ACTIVE);
            items.forEach((i) => {
              i.classList.remove(CLASS_ACTIVE);
            });

            item.classList.toggle(CLASS_ACTIVE, !status);
          }
        },
        false
      );
    });
  });
};
